import React from "react";

// local modules
import ProjectRoutes from "../Routes";

const App = () => {
  return (
      <ProjectRoutes />
  );
};

export default App;
