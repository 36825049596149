import React, { useState } from 'react';
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Modal, useMediaQuery, useTheme } from '@mui/material';

// Imports 

import { primaryClr, primaryTxtClr, welcomeClr, whiteClr } from '../../Components/colors';
import WindowImage from "../../Images/window.png";
import "./index.css";
import { useNavigate } from 'react-router-dom';


const Recommendaton = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile and tablet view
    const [sGiftReq, setGiftReq] = useState(false);

    const handleOpenReq = () => setGiftReq(true);
    const handleCloseReq = () => setGiftReq(false);

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        // if (newWindow) newWindow.opener = null
      }

    return(
        <Box id="recommendaton" style={{ backgroundColor: primaryClr, width:"100%", height: "auto", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column", paddingTop:'3rem', paddingBottom:"3rem" }} >
            <p className='Title-style-Head' style={{  fontFamily: 'Times New Roman', color: primaryTxtClr, marginTop:"3rem", marginBottom:"3rem" }} >
                RECOMMENDATIONS
            </p>

            <Grid 
                container 
                spacing={2} 
                style={{ 
                    marginTop: isMobile? "1rem" : '5rem', 
                    marginBottom: isMobile? "1rem" : '5rem', 
                    display:"flex", 
                    alignItems:"center", 
                    justifyContent:"center",
                }} 
            >

                <Grid item xs={12} md={12} lg={4} xl={4} style={{ marginTop: isMobile? '3rem' : 0  }} >
                    <div style={{ display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column", }} >
                        
                        <p className='Title-style1' style={{ fontFamily: 'Times New Roman', color: primaryTxtClr, marginTop:"1rem", marginBottom:"1rem" }} >
                            ACCOMMODATION
                        </p>
                        
                        <Typography style={{  fontFamily: 'Times New Roman', fontStyle:"italic", fontWeight: 200, fontSize: '1rem', color: primaryTxtClr, marginTop:"1rem", textAlign:"center", width:"75%" }} >
                            Follow the link below the see selection of best hotel
                            in the area, book it with special offer arranged by us.
                        </Typography>

                        <Button 
                            variant='contained' 
                            // onClick={() => openInNewTab('https://eur03.safelinks.protection.outlook.com/?url=https%3A%2F%2Ftinyurl.com%2FSarah-Khaled-Wedding&data=05%7C02%7Cselim.raafat%40lamaison-bleue.com%7C65929121a91d4220da8a08dc9e543b39%7Ce97705a2327c454e94b8aa7b827b1abb%7C0%7C0%7C638559331818132620%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&sdata=knGv83oRRpSeM6wGG9e%2B7ISs4iK52kZMn%2FnurVmbTaw%3D&reserved=0')} 
                            style={{ 
                                backgroundColor: welcomeClr, 
                                color: primaryTxtClr, 
                                border: `solid 0px ${primaryTxtClr}`, 
                                marginTop: isMobile? "2rem" : '5rem', 
                                fontSize:'0.85rem', 
                                fontStyle:'italic', 
                                fontFamily:"Times New Roman", 
                                fontWeight: 200, 
                                // paddingLeft:'3rem', 
                                // paddingRight:'3rem',
                                width: isMobile ? '70%' : '50%',
                            }} 
                        >
                            <a 
                                href="https://eur03.safelinks.protection.outlook.com/?url=https%3A%2F%2Ftinyurl.com%2FSarah-Khaled-Wedding&data=05%7C02%7Cselim.raafat%40lamaison-bleue.com%7C65929121a91d4220da8a08dc9e543b39%7Ce97705a2327c454e94b8aa7b827b1abb%7C0%7C0%7C638559331818132620%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&sdata=knGv83oRRpSeM6wGG9e%2B7ISs4iK52kZMn%2FnurVmbTaw%3D&reserved=0" 
                                target="_blank" 
                                rel="noreferrer"
                                style={{
                                    textDecoration:'none',
                                    color: primaryTxtClr, 
                                }}
                            >
                                Book with special offer
                            </a>
                        </Button>
                    </div>
                </Grid>

                <Grid item xs={12} md={12} lg={4} xl={4} style={{ marginTop: isMobile? '3rem' : 0  }} >
                   
                    <div style={{ display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column", }} >
                        <p className='Title-style1' style={{ fontFamily: 'Times New Roman', color: primaryTxtClr,  marginTop:"1rem", marginBottom:"1rem" }} >
                            ATTRACTIONS
                        </p>
                        
                        <Typography style={{  fontFamily: 'Times New Roman', fontStyle:"italic", fontWeight: 200, fontSize: '1rem', color: primaryTxtClr, marginTop:"1rem", textAlign:"center", width:"75%" }} >
                            For those who wish to enjoy a longer stay in Gouna,  in  the link below are some of our favourite places.
                        </Typography>

                        <Button 
                            variant='contained' 
                            onClick={()=> navigate("/AttractionPage")} 
                            style={{ 
                                backgroundColor: welcomeClr, 
                                color: primaryTxtClr, 
                                border: `solid 0px ${primaryTxtClr}`, 
                                marginTop: isMobile? "2rem" : '5rem', 
                                fontSize:'0.85rem', 
                                fontStyle:'italic', 
                                fontFamily:"Times New Roman", 
                                fontWeight: 200, 
                                // paddingLeft:'3rem', 
                                // paddingRight:'3rem',
                                minWidth:'40%',
                                width: isMobile ? '70%' : '50%',
                            }} 
                        >
                            View
                        </Button>
                    </div>

                </Grid>

                <Grid item xs={12} md={12} lg={4} xl={4} style={{ marginTop: isMobile? '3rem' : 0  }}  >
                    
                    <div style={{ display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column", }} >
                        <p className='Title-style1' style={{ fontFamily: 'Times New Roman', color: primaryTxtClr, marginTop:"1rem", marginBottom:"1rem" }} >
                            GIFTING
                        </p>
                        
                        <Typography style={{  fontFamily: 'Times New Roman', fontStyle:"italic", fontWeight: 200, fontSize: '1rem', color: primaryTxtClr, marginTop:"1rem", textAlign:"center", width:"75%" }} >
                            Your presence means the world to us, but if you'd like to give a gift, a monetary gesture would be appreciated.
                        </Typography>

                        <Button 
                            variant='contained' 
                            onClick={handleOpenReq} 
                            style={{ 
                                backgroundColor: welcomeClr, 
                                color: primaryTxtClr, 
                                border: `solid 0px ${primaryTxtClr}`, 
                                marginTop: isMobile? "2rem" : '5rem', 
                                fontSize:'0.85rem', 
                                fontStyle:'italic', 
                                fontFamily:"Times New Roman", 
                                fontWeight: 200, 
                                // paddingLeft:'3rem', 
                                // paddingRight:'3rem',
                                minWidth:'40%',
                                width: isMobile ? '70%' : '50%',
                            }} 
                        >
                            Request Details
                        </Button>
                    </div>

                </Grid>

            </Grid>


            {/* ******  Modal For Requesting Gift ****** */}
            <Modal
                open={sGiftReq}
                onClose={handleCloseReq}  // Close on background click
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box
                    style={{
                        minWidth: '40%',
                        maxWidth: '75%',
                        // backgroundColor:'red',
                        backgroundColor: welcomeClr,
                        borderRadius: '10px',  // Slight rounding of the corners
                        position: 'relative',
                        padding: '2rem',
                        overflowX:'scroll',
                        minHeight:'40%',
                        maxHeight: '80%'
                    }}
                >
                    {/* Close Button */}
                    <IconButton
                        onClick={handleCloseReq}
                        style={{ position: 'absolute', top: '10px', right: '10px' }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Grid
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        container 
                        spacing={2}
                    >
                        <Grid item xs={12} md={12} lg={6} xl={6} style={{ display:"flex", alignItems:"center", justifyContent:"center" }} >
                            {/* <img
                                src={WindowImage}
                                alt="Road Map"
                                style={{
                                    // width: '50%',
                                    maxHeight: 400,
                                    minHeight:350,
                                    marginRight: '2rem',
                                    borderRadius: '10px',
                                }}
                            /> */}
                        </Grid>
                        

                        {/* Right side (Text) */}
                        <Grid item xs={12} md={12} lg={12} xl={12} style={{ textAlign: 'center' }}>
                            <p className='Title-style-Head' style={{  fontFamily: 'Times New Roman', color: primaryTxtClr, marginTop:"3rem", marginBottom:"3rem" }}>
                                GIFTING
                            </p>

                            <Typography style={{  fontFamily: 'Times New Roman', fontStyle:"italic", fontWeight: 300, fontSize: '1rem', color: primaryTxtClr, marginTop:"1rem", textAlign:"center", width:"100%" }} >
                                Name: Khaled Mohamed Salah Amin El Sherif 
                            </Typography>
                            <Typography style={{  fontFamily: 'Times New Roman', fontStyle:"italic", fontWeight: 300, fontSize: '1rem', color: primaryTxtClr, textAlign:"center", width:"100%" }} >
                                Bank: Emirates NBD 
                            </Typography>
                            <Typography style={{  fontFamily: 'Times New Roman', fontStyle:"italic", fontWeight: 300, fontSize: '1rem', color: primaryTxtClr, textAlign:"center", width:"100%" }} >
                                Account no: 1015519179101
                            </Typography>
                            <Typography style={{  fontFamily: 'Times New Roman', fontStyle:"italic", fontWeight: 300, fontSize: '1rem', color: primaryTxtClr, textAlign:"center", width:"100%" }} >
                                IBAN: AE670260001015519179101
                            </Typography>
                            <Typography style={{  fontFamily: 'Times New Roman', fontStyle:"italic", fontWeight: 300, fontSize: '1rem', color: primaryTxtClr, textAlign:"center", width:"100%" }} >
                                Swift: EBILAEAD
                            </Typography>
                        </Grid>
                    </Grid>
                  
                </Box>
            </Modal>
        </Box>
    )
}

export default Recommendaton;