// import React from 'react';
// import { AppBar, Toolbar, Grid, Typography, Button } from '@mui/material';
// import { makeStyles } from '@mui/styles';
// import { primaryClr, primaryTxtClr } from './colors';

// // Custom styles for header
// const useStyles = makeStyles((theme) => ({
//   appBar: {
//     backgroundColor: primaryClr, // Light blue background color
//     boxShadow: 'none',
//     padding: '10px 0',
//   },
//   toolbar: {
//     justifyContent: 'center',
//   },
//   navItem: {
//     color: primaryTxtClr, // Dark blue text
//     fontSize: '6px',
//     fontWeight: 'bold',
//     cursor: 'pointer',
//     textAlign: 'center',
//     padding: '0 15px',
//     '&:hover': {
//       textDecoration: 'underline',
//     },
//   },
//   heading: {
//     fontFamily: "'Dancing Script', cursive", // Calligraphic font
//     color: primaryTxtClr,
//     fontSize: '2.5rem',
//     textAlign: 'center',
//   },
//   headerCenter: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
// }));

// const AppHeader = ({
//     xLocationScroll,
//     xItenaryScroll,
//     xRecommendationScroll,
//     xRSVPScroll,
//     xGuestBookScroll,
//     xQandAScroll,
// }) => {
//   const classes = useStyles();

//   return (
//     <AppBar position="relative" style={{
//         backgroundColor: primaryClr, // Light blue background color
//         boxShadow: 'none',
//         padding: '10px 0',
//     }}>
//       <Toolbar className={classes.toolbar}>
//         <Grid container alignItems="center">
//           {/* Left-side Navigation Links */}
//           <Grid item xs={4}>
//             <Grid container justifyContent="center">
//               {/* <Typography className={classes.navItem}>LOCATION</Typography> */}
//               <Typography className={classes.navItem} style={{ fontFamily:'Times New Roman' }}  onClick={xLocationScroll}>LOCATION</Typography>
//               <Typography className={classes.navItem} style={{ fontFamily:'Times New Roman' }} onClick={xItenaryScroll} >ITINERARY</Typography>
//               <Typography className={classes.navItem} style={{ fontFamily:'Times New Roman' }} onClick={xRecommendationScroll}  >RECOMMENDATION</Typography>
//             </Grid>
//           </Grid>

//           {/* Center Heading */}
//           <Grid item xs={4} className={classes.headerCenter}>
//             <Typography style={{  fontFamily: 'CustomFont', fontSize: '2rem', color: primaryTxtClr }} >
//               Sara & Khaled
//             </Typography>
//           </Grid>

//           {/* Right-side Navigation Links */}
//           <Grid item xs={4}>
//             <Grid container justifyContent="center">
//               <Typography className={classes.navItem} style={{ fontFamily:'Times New Roman' }} onClick={xRSVPScroll}  >RSVP</Typography>
//               <Typography className={classes.navItem} style={{ fontFamily:'Times New Roman' }} onClick={xGuestBookScroll}  >GUEST BOOK</Typography>
//               <Typography className={classes.navItem} style={{ fontFamily:'Times New Roman' }} onClick={xQandAScroll}  >Q&A</Typography>
//             </Grid>
//           </Grid>
//         </Grid>
//       </Toolbar>
//     </AppBar>
//   );
// };

// export default AppHeader;


import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText, useScrollTrigger, Slide } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material'; // Import Menu icon for mobile
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { Grid } from '@material-ui/core';

// local

import { primaryClr, primaryTxtClr } from './colors';
import './index.css';

// Custom styles for header
const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: primaryClr,
    boxShadow: 'none',
    padding: '10px 0',
  },
  toolbar: {
    justifyContent: 'center',
  },
  navItem: {
    color: primaryTxtClr,
    fontSize: '12px', // Increased the font size for readability
    fontWeight: 'bold',
    cursor: 'pointer',
    textAlign: 'center',
    padding: '0 15px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  heading: {
    fontFamily: "'Dancing Script', cursive",
    color: primaryTxtClr,
    // fontSize: '2.5rem',
    textAlign: 'center',
  },
  headerCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuButton: {
    color: primaryTxtClr,
  },
  drawerPaper: {
    width: 250,
  },
}));

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children ?? <div />}
    </Slide>
  );
}

const AppHeader = ({
  xLocationScroll,
  xItenaryScroll,
  xRecommendationScroll,
  xRSVPScroll,
  xGuestBookScroll,
  xQandAScroll,
  props
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile and tablet view
  const [drawerOpen, setDrawerOpen] = useState(false); // State for controlling drawer

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const renderNavItems1 = () => (
    <>
      <p className={'navItem-title'} style={{ fontFamily: 'Times New Roman', color: primaryTxtClr, }} onClick={xLocationScroll}>LOCATION</p>
      <p className={'navItem-title'} style={{ fontFamily: 'Times New Roman', color: primaryTxtClr, }} onClick={xItenaryScroll}>ITINERARY</p>
      <p className={'navItem-title'} style={{ fontFamily: 'Times New Roman', color: primaryTxtClr, }} onClick={xRecommendationScroll}>RECOMMENDATION</p>
      {/* <p className={'navItem-title'} style={{ fontFamily: 'Times New Roman', color: primaryTxtClr, }} onClick={xRSVPScroll}>RSVP</p>
      <p className={'navItem-title'} style={{ fontFamily: 'Times New Roman', color: primaryTxtClr, }} onClick={xGuestBookScroll}>GUEST BOOK</p>
      <p className={'navItem-title'} style={{ fontFamily: 'Times New Roman', color: primaryTxtClr, }} onClick={xQandAScroll}>Q&A</p> */}
    </>
  );

  const renderNavItems2 = () => (
    <>
      {/* <p className={'navItem-title'} style={{ fontFamily: 'Times New Roman', color: primaryTxtClr, }} onClick={xLocationScroll}>LOCATION</p>
      <p className={'navItem-title'} style={{ fontFamily: 'Times New Roman', color: primaryTxtClr, }} onClick={xItenaryScroll}>ITINERARY</p>
      <p className={'navItem-title'} style={{ fontFamily: 'Times New Roman', color: primaryTxtClr, }} onClick={xRecommendationScroll}>RECOMMENDATION</p> */}
      <p className={'navItem-title'} style={{ fontFamily: 'Times New Roman', color: primaryTxtClr, }} onClick={xRSVPScroll}>RSVP</p>
      <p className={'navItem-title'} style={{ fontFamily: 'Times New Roman', color: primaryTxtClr, }} onClick={xGuestBookScroll}>GUEST BOOK</p>
      <p className={'navItem-title'} style={{ fontFamily: 'Times New Roman', color: primaryTxtClr, }} onClick={xQandAScroll}> CONNECT </p>
    </>
  );

  return (
    <>
    {/* <HideOnScroll {...props}> */}
      <AppBar 
        position="fixed" 
        className={'appBar'} 
        style={{
            backgroundColor: primaryClr,
            boxShadow: 'none',
            padding: '10px 0',
          }} 
        >
        <Toolbar className={classes.toolbar}>
          <Grid container alignItems="center" spacing={0} >
            {/* Mobile/Tablet: Show Menu Icon */}
            {isMobile ? (
              <>
                <Grid item>
                  <IconButton edge="start" onClick={toggleDrawer(true)} className={classes.menuButton}>
                    <MenuIcon />
                  </IconButton>
                </Grid>

                {/* Center Heading for mobile */}
                <Grid 
                  item 
                  // className={classes.headerCenter}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width:'85%'
                  }}
                >
                  {/* <Typography 
                    className={classes.heading} 
                    style={{ 
                      fontFamily: 'CustomFont',
                      // fontFamily: "'Dancing Script', cursive",
                      color: primaryTxtClr,
                      fontSize: '1.75rem',
                      textAlign: 'center', 
                      marginRight: '1.5rem'

                    }}> 
                    Sara & Khaled
                  </Typography> */}

                  <p 
                    className={'heading-title'} 
                    style={{ 
                      // fontFamily: 'CustomFont', 
                      // fontSize: '2.5rem',
                      color: primaryTxtClr,
                      // textAlign: 'center',
                      // marginTop: '0.56rem'
                    }}>
                    Sara & Khaled
                  </p>
                </Grid>

                <Drawer
                  anchor="left"
                  open={drawerOpen}
                  onClose={toggleDrawer(false)}
                  // classes={{ paper: classes.drawerPaper }}
                  style={{
                    backgroundColor: primaryClr,
                    width: 250
                  }}
                >
                  <List onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)} style={{ backgroundColor: primaryClr, height:"100%", width:250 }} >
                    {/* <Typography 
                      className={classes.heading} 
                      style={{ 
                        fontFamily: 'CustomFont', 
                        // fontFamily: "'Dancing Script', cursive",
                        color: primaryTxtClr,
                        fontSize: '1.75rem',
                        textAlign: 'center',
                      }}>
                      Sara & Khaled
                    </Typography> */}

                    <p 
                      className={'heading-title'} 
                      style={{ 
                        // fontFamily: 'CustomFont', 
                        // fontSize: '2.5rem',
                        color: primaryTxtClr,
                        // textAlign: 'center',
                        // marginTop: '0.56rem'
                      }}>
                      Sara & Khaled
                    </p>
                    <ListItem button onClick={xLocationScroll}>
                      <ListItemText primary="LOCATION" primaryTypographyProps={{ fontFamily:"Times New Roman", color: primaryTxtClr }} />
                    </ListItem>
                    <ListItem button onClick={xItenaryScroll}>
                      <ListItemText primary="ITINERARY" primaryTypographyProps={{ fontFamily:"Times New Roman", color: primaryTxtClr }} />
                    </ListItem>
                    <ListItem button onClick={xRecommendationScroll}>
                      <ListItemText primary="RECOMMENDATION" primaryTypographyProps={{ fontFamily:"Times New Roman", color: primaryTxtClr }} />
                    </ListItem>
                    <ListItem button onClick={xRSVPScroll}>
                      <ListItemText primary="RSVP" primaryTypographyProps={{ fontFamily:"Times New Roman", color: primaryTxtClr }} />
                    </ListItem>
                    <ListItem button onClick={xGuestBookScroll}>
                      <ListItemText primary="GUEST BOOK" primaryTypographyProps={{ fontFamily:"Times New Roman", color: primaryTxtClr }} />
                    </ListItem>
                    <ListItem button onClick={xQandAScroll}>
                      <ListItemText primary="Q&A" primaryTypographyProps={{ fontFamily:"Times New Roman", color: primaryTxtClr }} />
                    </ListItem>
                  </List>
                </Drawer>
              </>
            ) : (
              // Desktop: Show full navigation bar
              <>
                <Grid item xs={4.5} md={4} xl={4} lg={4} style={{ display:"flex", alignItems:"center", justifyContent:"space-between" }} >
                  <Grid container justifyContent="center">
                    {renderNavItems1()}
                  </Grid>
                </Grid>

                <Grid item xs={3} md={4} xl={4} lg={4} >
                  <p 
                    className={'heading-title'} 
                    style={{ 
                      // fontFamily: 'CustomFont', 
                      // fontSize: '2.5rem',
                      color: primaryTxtClr,
                      // textAlign: 'center',
                      // marginTop: '0.56rem'
                    }}>
                    Sara & Khaled
                  </p>
                </Grid>

                <Grid item xs={4.5} md={4} xl={4} lg={4} style={{ display:"flex", alignItems:"center", justifyContent:"space-between" }} >
                  <Grid container justifyContent="center">
                    {renderNavItems2()}
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    {/* </HideOnScroll> */}
    </>
  );
};

export default AppHeader;

