import { Typography } from '@mui/material';
import React from 'react';
import { primaryTxtClr, welcomeClr } from '../../Components/colors';


const WelcomeLetter = () => {
    return(
        <div className='welcome-letter-style' style={{ backgroundColor: welcomeClr }} >
            
            {/* <Typography style={{ fontSize:'2rem', fontWeight: 'bolder', fontFamily:'Times New Roman', letterSpacing: 1, color: primaryTxtClr, textAlign:"center" }} >
                WELCOME LETTER
            </Typography> */}

            <p className='heading-style' style={{ fontFamily:"Times New Roman", color: primaryTxtClr, textAlign:"center" }} >
                WELCOME LETTER
            </p>
            
            <p className='text-style' style={{ color:primaryTxtClr, fontWeight: 200, fontFamily: "Times New Roman", fontStyle:'italic', textAlign:"center", }}>
                We are overjoyed to invite you to celebrate our wedding! Your presence means the world to us, and we can't wait to share this special moment with you.
            </p>
            
            <p  className='text-style' style={{ color:primaryTxtClr, fontWeight: 200, fontFamily: "Times New Roman", fontStyle:'italic', textAlign:"center", }} >
                Join us as we embark on this beautiful journey together, filled with love, laughter, and cherished memories.
            </p>
            
            <p  className='text-style' style={{ color:primaryTxtClr, fontWeight: 200, fontFamily: "Times New Roman", fontStyle:'italic', textAlign:"center", }} >
                With love and gratitude,
            </p>

            <p className='text-style' style={{ color:primaryTxtClr, fontWeight: 400, fontFamily: "Times New Roman", fontStyle:'italic', textAlign:"center", }}  >
                Sara & Khaled
            </p>

        </div>
    )
}

export default WelcomeLetter;