import axios from "axios";
import { baseUrl } from "./baseUrl";
// const baseUrl = 'http://localhost:8000'

const axiosInstance = axios.create({
  // baseURL: `${process.env.REACT_APP_BASE_URL}`,
  baseURL: baseUrl,
  responseType: "json",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const httpRequest = async (axiosData) => {
  try {
    const res = await axiosInstance.request(axiosData);
    return res?.data;
  } catch (error) {
    throw error;
  }
};

