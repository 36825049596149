export const GET_API_LOGIN = (email, secret_code) => {
    return {
      method: "POST",
      url: `/api/getAccess`,
      data: {
        email,
        secret_code,
      },
    };
  };

  export const GET_GUEST_MESSAGES = ( inviter_id ) => {
    return {
      method: "POST",
      url: `/api/allMessages`,
      data: {
        inviter_id
      },
    };
  };

  export const POST_GUEST_MESSAGE_API = ( inviter_id, name, message ) => {
    return {
      method: "POST",
      url: `/api/new_message`,
      data: {
        inviter_id,
        name,
        message
      },
    };
  };


  export const POST_RSVP_API = ( 
    inviter_id,
    name,
    re_res,
    ac_sp,
    p_s,
    full_name,
    song
    ) => {
    return {
      method: "POST",
      url: `/api/rsvp`,
      data: {
        inviter_id,
        name,
        re_res,
        ac_sp,
        p_s,
        full_name,
        song
      },
    };
  };