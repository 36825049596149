import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Container, Button, makeStyles, ThemeProvider, createTheme, CssBaseline, Modal, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Local 

import './index.css';
import HomePage from './home';
import AppHeader from '../../Components/Header';
import AppFooter from '../../Components/Footer';
import Location from '../Location';
import Itinerary from '../Itenirary';
import Recommendaton from '../Recommendation';
import RSVP from '../RSVP';
import GuestBook from '../GuestBook';
import QuestAndAns from '../QuesAndAns';
import { primaryClr, primaryTxtClr, welcomeClr, whiteClr } from '../../Components/colors';
import WindowImage from "../../Images/window2.png";
import BaylImage from "../../Images/bayl.png";
import RSVPForm from './RSVPForm';

function HomePageRoutes(props) {
    const [sRSVPModal, setRSVPModal] = useState(false);

    const handleOpen = () => setRSVPModal(true);
    const handleClose = () => setRSVPModal(false);

    const scrollToSection = (id) => {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    };
    

  return (
    <div>
        <AppHeader
            xLocationScroll={() => scrollToSection('location')}
            xItenaryScroll={() => scrollToSection('itinerary')}
            xRecommendationScroll={() => scrollToSection('recommendaton')}
            xRSVPScroll={handleOpen}
            xGuestBookScroll={() => scrollToSection('guestbook')}
            xQandAScroll={() => scrollToSection('QandA')}
        />

        <div id="/">
          <HomePage  />
        </div>

        <div id="location">
            <Location />
        </div>

        <div id="itinerary">
            <Itinerary />
        </div>

        <div id="recommendaton">
            <Recommendaton />
        </div>

        {/* <div id="rsvp">
            <RSVP />
        </div> */}

        <div id="guestbook">
            <GuestBook />
        </div>

        <div id="QandA" style={{backgroundColor: primaryClr, width:'100%'}} >
            <QuestAndAns />
        </div>

        {/* <div className='scroll-to-top-style' >
            <p> This is Testing to check ... </p>
        </div> */}

        {/* <AppFooter /> */}

        <Modal
            open={sRSVPModal}
            onClose={handleClose}  // Close on background click
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
            <Box
                style={{
                    // width: '50%',
                    backgroundColor: welcomeClr,
                    borderRadius: '10px',  // Slight rounding of the corners
                    position: 'relative',
                    padding: '2rem',
                    paddingTop: 0,
                    display:"flex",
                    alignItems:"center",
                    // justifyContent: 'center',
                    flexDirection:"column",
                    overflowX:'scroll',
                    maxHeight: '80%'
                }}
                className={"form-style"}
            >
                {/* <img
                    src={BaylImage}
                    style={{
                        width: '100%',
                        height: 'auto',
                        // marginRight: '2rem',
                        borderRadius: '10px',
                        marginBottom:'1rem'
                    }}
                /> */}

                {/* Flex container for image and text */}
                {/* <Box
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                > */}
                {/* Left side (Image) */}
                    {/* <img
                        src={WindowImage}
                        alt="Road Map"
                        style={{
                        width: '50%',
                        height: 600,
                        marginRight: '2rem',
                        borderRadius: '10px',
                        }}
                    /> */}

                    {/* Right side (Text) */}
                    <Box style={{  textAlign: 'center'  }} className={"form-style1"} >
                        <p className='Title-style' style={{  fontFamily: 'Times New Roman', fontWeight:"bold", color: primaryTxtClr }} >
                            R S V P
                        </p>

                        <p className='Title-style' style={{  fontFamily: 'Times New Roman', fontSize:"1.25rem",fontStyle:'italic', fontWeight:"normal", color: primaryTxtClr }} >
                            Kindly note,  we warmly welcome only spouses as plus ones. We
                            appreciate your understanding.
                        </p>
                        <RSVPForm xCloseModal={handleClose} />
                    </Box>
                </Box>
            {/* </Box> */}
        </Modal>
    </div>
  );
}

export default HomePageRoutes;