import { Box, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { Button, CircularProgress, useMediaQuery, useTheme } from '@mui/material';

// Local 

import "./mainPage.css";
import { primaryClr, primaryTxtClr, welcomeClr } from '../../Components/colors';

const AppMainPage = ({
    xOnClick,
    xLoader,
    xError
}) => {
  const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile and tablet view
    const [sEmail, setEmail] = useState("");
    const [sAccessCode, setAccessCode] = useState("");

    return(
        <Box style={{ backgroundColor: primaryClr, width:"100%", height: "98vh", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column" }} >
            <p className='Title-style' style={{  fontFamily: 'CustomFont', color: primaryTxtClr }} >
                Sara & Khaled
            </p>
            {/* <p style={{ fontFamily: 'CustomFont', fontSize: '3rem', color: primaryTxtClr }} >
                Sara & Khalid
            </p> */}

            <div style={{ marginTop: '1rem', marginBottom: '1rem' }} />
            <input 
                value={sEmail}
                onChange={(e)=> setEmail(e.target.value)}
                className='input-style'
                style={{ backgroundColor: primaryClr, color: primaryTxtClr, fontSize: '1.2rem', fontStyle:'italic', fontFamily: 'Times New Roman', textAlign:"center", height: '3rem', minWidth:'25%', lineHeight: '3rem' }}
                placeholder='your e-mail:'
                maxLength={42}
                
            />

            <div className='line-style' style={{ border: `solid 0.4px ${primaryTxtClr}`, borderRadius: 5, marginTop: '1rem', marginBottom: '1rem' }} />

            <input 
                value={sAccessCode}
                onChange={(e)=> setAccessCode(e.target.value)}
                className='input-style'
                style={{ backgroundColor: primaryClr, color: primaryTxtClr, fontSize: '1.2rem', fontStyle:'italic', fontFamily: 'Times New Roman', textAlign:"center", height: '3rem', minWidth:'25%', lineHeight: '3rem' }}
                placeholder='access code:'
                maxLength={22}
            />

            <Button variant='contained'  onClick={()=> xOnClick(sEmail, sAccessCode)} style={{ backgroundColor: primaryTxtClr, color: welcomeClr, border: `none`, marginTop: '5rem', fontSize:'0.85rem', fontStyle:'italic', fontFamily:"Times New Roman", fontWeight: 200, paddingLeft:'5rem', paddingRight:'5rem'}} >
                {
                    xLoader ?
                        <CircularProgress color='black' size={30}  />
                        :                
                        "Submit"
                }
            </Button>
            
            {
                xError && <p style={{ fontFamily:'Times New Roman' }} > You haven't access or check your credentials or ask for support </p>
            }

           

        </Box>
    )
}

export default AppMainPage;
