import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Container, Button, ThemeProvider, createTheme, CssBaseline, Modal, Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { primaryClr, primaryTxtClr } from '../../Components/colors';
import { makeStyles } from '@mui/styles';

// local 

import "./index.css";

const useStyles = makeStyles((theme) => ({
    heading: {
      fontFamily: "'Dancing Script', cursive",
      color: primaryTxtClr,
      fontSize: '2.5rem',
      textAlign: 'center',
    },
  }));

function AttractionPage(props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile and tablet view

    const classes = useStyles();
    const [animate, setAnimate] = useState(false);
    const [sRSVPModal, setRSVPModal] = useState(false);

    useEffect(() => {
        setAnimate(true);
        window.scrollTo(0, 0);
    }, []);

  return (
       <div style={{  width:"100%", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column", backgroundColor: primaryClr, paddingBottom: "5rem" }} >
            <Typography
                // className={classes.heading} 
                style={{ 
                    fontFamily: 'CustomFont',
                    color: primaryTxtClr,
                    textAlign: 'center', 
                    fontSize: isMobile ? '1.5rem' : '2.25rem',
                    marginRight: '1.5rem',
                    marginTop:'2rem',
                    marginBottom:'2rem',
                }}
            >
                Sara & Khaled
            </Typography>

            <div className="party-page" style={{ color: primaryTxtClr }} >
            {/* Beach Clubs and Pool Parties Section */}
            <section className="section">
                <h2 className="section-title" style={{ fontFamily:"Times new Roman" }} >BEACH CLUBS AND POOL PARTIES</h2>
                <div className="section-content">
                    <h3 style={{ fontFamily:"Times new Roman", fontStyle:"italic" }} >Du Port Pool Club</h3>
                    <p style={{ fontFamily:"Times new Roman" }} >This club offers poolside parties with DJs, themed events, music, and cocktails.</p>
                    <h3 style={{ fontFamily:"Times new Roman", fontStyle:"italic" }} >Club 88</h3>
                    <p style={{ fontFamily:"Times new Roman" }} >Sunset parties, starry dancing, chic atmosphere, outdoor pool, and top DJ performances.</p>
                    <h3 style={{ fontFamily:"Times new Roman", fontStyle:"italic" }}  >La Playa Beach Club</h3>
                    <p style={{ fontFamily:"Times new Roman" }} >Trendy beach club with daytime relaxation, evening parties, live music, and fire shows.</p>
                </div>
            </section>

            <div style={{ display:"flex", alignItems:"center", justifyContent:"center" }} >
                <div className="divider" />
            </div>

            {/* Bars and Lounges Section */}
            <section className="section">
                <h2 className="section-title" style={{ fontFamily:"Times new Roman" }} >BARS AND LOUNGES</h2>
                <div className="section-content">
                    <h3 style={{ fontFamily:"Times new Roman", fontStyle:"italic" }} >Pier 88</h3>
                    <p style={{ fontFamily:"Times new Roman" }} >Pier 88 is a floating lounge with cocktails, music, Mediterranean dishes, and vibrant evenings.</p>
                    <h3 style={{ fontFamily:"Times new Roman", fontStyle:"italic" }} >Villa Coconut</h3>
                    <p style={{ fontFamily:"Times new Roman" }} >Villa Coconut features bohemian style, live music, DJ nights, themed parties, and a tropical garden setting.</p>
                    <h3 style={{ fontFamily:"Times new Roman", fontStyle:"italic" }} >Aurora Club</h3>
                    <p style={{ fontFamily:"Times new Roman" }} >Aurora, near the Marina, is perfect for dancing with guest DJs and a large dance floor.</p>
                </div>
            </section>

           <div style={{ display:"flex", alignItems:"center", justifyContent:"center" }} >
                <div className="divider" />
            </div>

            {/* Live Music Venues Section */}
            <section className="section">
                <h2 className="section-title" style={{ fontFamily:"Times new Roman" }}>LIVE MUSIC VENUES</h2>
                <div className="section-content">
                    <h3 style={{ fontFamily:"Times new Roman", fontStyle:"italic" }}>The Bartender El Gouna</h3>
                    <p style={{ fontFamily:"Times new Roman" }} >Live music nights with local bands create a cozy atmosphere for drinks and music lovers.</p>
                    <h3 style={{ fontFamily:"Times new Roman", fontStyle:"italic" }}> Smokery Beach </h3>
                    <p style={{ fontFamily:"Times new Roman" }} >Elegant dining with live music and DJ nights, combining a lively and refined experience.</p>
                </div>
            </section>

            <div style={{ display:"flex", alignItems:"center", justifyContent:"center" }} >
                <div className="divider" />
            </div>

            {/* Live Music Venues Section */}
            <section className="section">
                <h2 className="section-title" style={{ fontFamily:"Times new Roman" }}>Late-Night Dining</h2>
                <div className="section-content">
                    <h3 style={{ fontFamily:"Times new Roman", fontStyle:"italic" }}>Chuchichäschtli - Swiss House</h3>
                    <p style={{ fontFamily:"Times new Roman" }} >A cozy spot with Swiss and European cuisine, perfect for late-night dining and unwinding.</p>
                </div>
            </section>
            
            </div>
       </div>
  );
}

export default AttractionPage;