import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Local

import AppHeader from "../Components/Header";
import Footer from "../Components/Footer";
// import "./index.css";
import AppMainPage from '../Pages/MainPage';
import "../Font.css";
import './index.css'
import HomePage from '../Pages/HomePage/home';
import HomePageRoutes from '../Pages/HomePage';
import { GET_API_LOGIN } from '../Pages/Server/API';
import { httpRequest } from '../Pages/Server/Axios';
import AttractionPage from '../Pages/Attractions';

const ProjectRoutes = ({ path, open }) => {

  const [sAuthentic, setAuthentic] = useState(true);
  const [sLoader, setLoader] = useState(false);
  const [sError, setError] = useState(false);
  const [sUserDetail, setUserDetail] = useState({});



  const Login = async(email, secret_code) => {
      // setAuthentic(false);
      setLoader(true)
      setError(false)
      const LOGIN_API_CREDENTIALS = GET_API_LOGIN(email, secret_code);
      
      try {
        const response = await httpRequest(LOGIN_API_CREDENTIALS);
        if(response.success){
          setLoader(false)
          setError(false);

          localStorage.setItem('user', JSON.stringify(response.user));
        }
        else{
          setLoader(false)
          setError(true)
        }
      } catch (error) {
          setLoader(false)
          setError(true)
      }
  }

  useEffect(() => {
    const userDetails = localStorage.getItem("user");
    const userValue = JSON.parse(userDetails);
    setUserDetail(userValue);
  }, [sLoader]); 



  // console.log("*** user Details  ***** ", sUserDetail);

  return (
    <Router>
      <div className="main-container" >
        <Routes>
          {/* <Route
            exact
            path="/"
            element={
              sUserDetail == null? 
                  <AppMainPage xOnClick={Login} xLoader={sLoader} xError={sError} /> 
                  :
                  <HomePageRoutes />
            }
          /> */}

          {
            sUserDetail == null? 
            // sAuthentic ?
              <Route
                exact
                path="/"
                element={<AppMainPage xOnClick={Login} xLoader={sLoader} xError={sError} />}
              />
              :
              <>
                <Route 
                  exact
                  path='/'
                  element={ <HomePageRoutes />}
                />

                <Route 
                  exact
                  path='/AttractionPage'
                  element={ <AttractionPage />}
                />
              </>
          }
        </Routes>
      </div>
    </Router>
  );
};

export default ProjectRoutes;
