import React, { useState } from 'react';
import { Box, TextField, Typography } from '@material-ui/core';

// Local 

import { primaryClr, primaryTxtClr } from '../../Components/colors';
import Banner from "../../Images/banner.PNG";
import WelcomeLetter from './WelcomeLetter';
import './index.css';

const HomePage = () => {

    return(
        <Box id="home" className='home-container' style={{ backgroundColor: primaryClr }} >
        {/* <Box id="home" style={{ backgroundColor: primaryClr, width:"100%", height:"65vh",  display:"flex", alignItems:"center", flexDirection:"column", paddingTop:'7rem' }} > */}
            <Typography variant='' style={{  fontFamily: 'Times New Roman', color: primaryTxtClr }} className='header-style' >
                DECEMBER 19 & 20, 2024
            </Typography>
            <Typography variant='' style={{  fontFamily: 'Times New Roman', fontStyle:"italic", fontWeight: 200, color: primaryTxtClr }} className='sub-header-style' >
                La Maison Bleu , El Gouna, Egypt
            </Typography>

            <img src={Banner} style={{ width:"100%", height:"95%", marginTop:'1rem' }} />
            <WelcomeLetter />
        </Box>
    )
}

export default HomePage;
