import React, { useEffect, useState } from 'react';
import { TextField, Button, RadioGroup, FormControlLabel, Radio, Typography, FormControl, FormLabel, Box, CircularProgress, Alert, SnackbarContent, Snackbar } from '@mui/material';

// Local

import { primaryClr, primaryTxtClr, welcomeClr, whiteClr } from '../../Components/colors';
import { httpRequest } from '../Server/Axios';
import { POST_RSVP_API } from '../Server/API';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'; 

const RSVPForm = ({
  xCloseModal
}) => {
  const [fullName, setFullName] = useState('');
  const [hasDietaryRestrictions, setHasDietaryRestrictions] = useState('');
  const [hasSpouse, setHasSpouse] = useState('');
  const [spouseName, setSpouseName] = useState('');
  const [wantsSong, setWantsSong] = useState('');
  const [songName, setSongName] = useState('');
  const [userDetail, setUserDetail] = useState({});
  const [sLoader, setLoader] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

    const AlertFunction = () => {
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };


  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      fullName,
      hasDietaryRestrictions,
      hasSpouse,
      spouseName,
      wantsSong,
      songName
    };
    console.log('Form Data:', formData);
  };

  useEffect(() => {
    const userDetails = localStorage.getItem("user");
    const userValue = JSON.parse(userDetails);
    setUserDetail(userValue);
}, []);

  const postRSVP = async() => {
    setLoader(true)
    const POST_RSVP_DATA = POST_RSVP_API( userDetail.id, fullName, hasDietaryRestrictions, hasSpouse, wantsSong, spouseName, songName  );
    
    try {
      const response = await httpRequest(POST_RSVP_DATA);
      // console.log("***** List of Respoonse Messages ***** ", response);
      if(response.success){
          // alert("Successfully add");
          setLoader(false);
          setFullName("")
          setHasDietaryRestrictions("")
          setHasSpouse("")
          setSpouseName("")
          setWantsSong("")
          setSongName("");
          AlertFunction();
          setTimeout(() => {
            xCloseModal();
          }, 2000);
      }
      else{
        setLoader(false)
      }
    } catch (error) {
        setLoader(false)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      {/* Full Name */}
        <input 
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            className='input-style'
            style={{ 
                backgroundColor: whiteClr, 
                color:"gray", 
                fontSize: '1.2rem', 
                fontFamily: 'serif', 
                textAlign:"left", 
                height: '3rem', 
                // minWidth:'50%', 
                width:"100%",
                lineHeight: '3rem',
                borderBottom: `solid 1px ${primaryTxtClr}`,
                paddingLeft: '0.5rem'
            }}
            placeholder='Your full name'
            maxLength={42}
        />

          {/* Spouse Question */}
        <FormControl component="fieldset" margin="normal" fullWidth>
          <FormLabel component="legend" style={{ textAlign:"left" }}> Will you be accompanied by your spouse?</FormLabel>
          <RadioGroup
            value={hasSpouse}
            onChange={(e) => setHasSpouse(e.target.value)}
            row
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>

        {/* Spouse Name Field (Conditionally Rendered) */}
        {hasSpouse === 'yes' && (
          <input 
              value={spouseName}
              onChange={(e) => setSpouseName(e.target.value)}
              className='input-style'
              style={{ 
                  backgroundColor: whiteClr, 
                  color:"gray", 
                  fontSize: '1.2rem', 
                  fontFamily: 'serif', 
                  textAlign:"left", 
                  height: '3rem', 
                  // minWidth:'50%', 
                  width:"100%",
                  lineHeight: '3rem',
                  borderBottom: `solid 1px ${primaryTxtClr}`,
                  paddingLeft: '0.5rem'
              }}
              placeholder='Full name'
              maxLength={42}
          />
        )}

      {/* Dietary Restrictions */}
      <FormControl component="fieldset" margin="normal" fullWidth>
        <FormLabel component="legend" style={{ textAlign:"left" }}> Do you have any dietary restrictions or allergies?</FormLabel>
        <RadioGroup
          value={hasDietaryRestrictions}
          onChange={(e) => setHasDietaryRestrictions(e.target.value)}
          row
        >
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>


      {/* Song Request */}
      {/* <FormControl component="fieldset" margin="normal" fullWidth>
        <FormLabel component="legend" style={{ textAlign:"left" }} >3: Is there a particular song you would like to hear during the reception?</FormLabel>
        <RadioGroup
          value={wantsSong}
          onChange={(e) => setWantsSong(e.target.value)}
          row
        >
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl> */}

      {/* Song Name Field (Conditionally Rendered) */}
      {wantsSong === 'yes' && (
        <input 
            value={songName}
            onChange={(e) => setSongName(e.target.value)}
            className='input-style'
            style={{ 
                backgroundColor: whiteClr, 
                color:"gray", 
                fontSize: '1.2rem', 
                fontFamily: 'serif', 
                textAlign:"left", 
                height: '3rem', 
                // minWidth:'50%', 
                width:"100%",
                lineHeight: '3rem',
                borderBottom: `solid 1px ${primaryTxtClr}`,
                paddingLeft: '0.5rem'
            }}
            placeholder='Song name'
            maxLength={42}
        />
      )}

      {/* Submit Button */}
      <Box textAlign="center" margin="normal">
        <Button variant='outlined' onClick={postRSVP} style={{  color: primaryTxtClr, border: `solid 1px ${primaryTxtClr}`, marginTop: '2rem', fontSize:'0.85rem', fontFamily:"sans-serif", fontWeight: 200, paddingLeft:'5rem', paddingRight:'5rem'}} >
            {
              sLoader ?
                <CircularProgress color={primaryTxtClr} size={25} />
                :
                "Submit"
            }
        </Button>
      </Box>

      <Snackbar 
          open={openSnackbar} 
          autoHideDuration={3000} 
          onClose={handleCloseSnackbar} 
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
              Successfully added!
          </Alert>
      </Snackbar>
      
      <FormLabel component="legend" style={{ textAlign:"center", marginTop:"1rem", fontSize:'0.75rem' }}> Last day to confirm is by October 1st</FormLabel>

    </form>
  );
};

export default RSVPForm;
