import React, { useState } from 'react';
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import { Button, Grid2, IconButton, Modal, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Locals

import { primaryClr, primaryTxtClr, welcomeClr, whiteClr } from '../../Components/colors';
import RoadMap from "../../Images/road.png";
import RoadMapVertical from "../../Images/roadVertical1.png";
import "./index.css";

const Location = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile and tablet view
    const [flightModal, setFlightModal] = useState(false);
    const [carModal, setCarModal] = useState(false);

    const handleOpen = () => setFlightModal(true);
    const handleClose = () => setFlightModal(false);

    const handleOpenCar = () => setCarModal(true);
    const handleCloseCar = () => setCarModal(false);

    return(
        <Box id="location" style={{ backgroundColor: whiteClr, width:"100%", minHeight: "65vh", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column", paddingTop: isMobile ? '12rem' :'22rem' }} >
            <p className='title-style-head' style={{ fontFamily:'Times New Roman', color: primaryTxtClr, textAlign:'center', textDecoration:'none', }} >
                GET TO GOUNA
            </p>

            <p className='SubStyle' style={{ color: primaryTxtClr, textDecoration:'none', /* fontWeight: 200, fontFamily: "Times New Roman", fontStyle:'italic', textAlign:"center", marginTop:'2rem', minWidth:'40%', maxWidth: '50%'  */ }}>
                El Gouna, on Egypt's Red Sea coast, is a stunning location with clear lagoons and sandy beaches. To get to El Gouna you'll first arrive in Cairo. 
            </p>
            <div style={{ display:"flex", alignItems:"center", justifyContent:"flex-end", minWidth:"50%", maxWidth:'90%', marginTop: "3rem" }} >
                <Button variant='outlined' style={{ color: primaryTxtClr, border:`none`, textDecoration:'none', paddingLeft:'1rem', paddingRight:'1rem', backgroundColor: welcomeClr }} onClick={handleOpen} >
                    BY FLIGHT
                </Button>
                <Button variant='outlined' style={{ color: primaryTxtClr, border:`none`, textDecoration:'none', paddingLeft:'1rem', paddingRight:'1rem', backgroundColor: welcomeClr, marginLeft: '1rem' }} onClick={handleOpenCar}  >
                    BY CAR
                </Button>
            </div>
            <img src={RoadMap} className='image-style' />
            <img src={RoadMapVertical} className='image-style-2' />

            {/* ******  Modal For Flight...  ****** */}
            <Modal
                open={flightModal}
                onClose={handleClose}  // Close on background click
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                <Box
                    style={{
                        minWidth: '40%',
                        maxWidth: '75%',
                        // backgroundColor:'red',
                        backgroundColor: whiteClr,
                        borderRadius: '10px',  // Slight rounding of the corners
                        position: 'relative',
                        padding: '2rem',
                        overflowX:'scroll',
                        maxHeight: '80%'
                    }}
                >
                    {/* Close Button */}
                    <IconButton
                        onClick={handleClose}
                        style={{ position: 'absolute', top: '10px', right: '10px' }}
                    >
                    <CloseIcon />
                    </IconButton>

                    {/* Flex container for image and text */}
                    <Grid
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        container 
                        spacing={2}
                    >
                        <Grid item xs={12} md={12} lg={6} xl={6} style={{ display:"flex", alignItems:"center", justifyContent:"center" }} >
                        {
                                !isMobile && 
                                    <img
                                        src={RoadMapVertical}
                                        alt="Road Map"
                                        style={{
                                            // width: '50%',
                                            maxHeight: 800,
                                            minHeight:600,
                                            marginRight: '2rem',
                                            borderRadius: '10px',
                                        }}
                                    />
                            }
                        </Grid>
                        

                        {/* Right side (Text) */}
                        <Grid item xs={12} md={12} lg={6} xl={6} style={{ textAlign: 'center' }}>
                            <Typography
                            variant="body1"
                            style={{
                                fontSize: '1.25rem',
                                fontWeight: 200,
                                fontFamily: "Times New Roman",
                                fontStyle: 'italic',
                                color: primaryTxtClr,
                                marginBottom: '1.5rem',
                            }}
                            >
                            Take a flight to Hurghada International Airport, followed by a
                            30-minute drive to El Gouna.
                            </Typography>
                          
                            <Typography
                            variant="body1"
                            style={{
                                fontSize: '1.25rem',
                                fontWeight: 200,
                                fontFamily: "Times New Roman",
                                fontStyle: 'italic',
                                color: primaryTxtClr,
                            }}
                            >
                            Please coordinate with your chosen hotel to arrange a pickup from
                            the airport to your hotel.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
       
            {/* ******  Modal For Car...  ****** */}
            <Modal
                open={carModal}
                onClose={handleCloseCar}  // Close on background click
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                <Box
                    style={{
                        minWidth: '40%',
                        maxWidth: '75%',
                        // backgroundColor:'red',
                        backgroundColor: whiteClr,
                        borderRadius: '10px',  // Slight rounding of the corners
                        position: 'relative',
                        padding: '2rem',
                        overflowX:'scroll',
                        maxHeight: '80%'
                    }}
                >
                    {/* Close Button */}
                    <IconButton
                        onClick={handleCloseCar}
                        style={{ position: 'absolute', top: '10px', right: '10px' }}
                    >
                    <CloseIcon />
                    </IconButton>

                    {/* Flex container for image and text */}
                    <Grid
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        container 
                        spacing={2}
                    >
                        <Grid item xs={12} md={12} lg={6} xl={6} style={{ display:"flex", alignItems:"center", justifyContent:"center" }} >
                            {
                                !isMobile && 
                                    <img
                                        src={RoadMapVertical}
                                        alt="Road Map"
                                        style={{
                                            // width: '50%',
                                            maxHeight: 800,
                                            minHeight:600,
                                            marginRight: '2rem',
                                            borderRadius: '10px',
                                        }}
                                    />
                            }
                        </Grid>
                        

                        {/* Right side (Text) */}
                        <Grid item xs={12} md={12} lg={6} xl={6} style={{ textAlign: 'center' }}>
                            <Typography
                                variant="body1"
                                style={{
                                    fontSize: '1.25rem',
                                    fontWeight: 200,
                                    fontFamily: "Times New Roman",
                                    fontStyle: 'italic',
                                    color: primaryTxtClr,
                                    marginBottom: '1.5rem',
                                }}
                            >
                                Drive directly from Cairo, which takes about 4 hours. 
                            </Typography>
                            <Typography
                                variant="body1"
                                style={{
                                    fontSize: '1.25rem',
                                    fontWeight: 200,
                                    fontFamily: "Times New Roman",
                                    fontStyle: 'italic',
                                    color: primaryTxtClr,
                                }}
                            >
                                For any transportation via car from Cairo to Gouna, we suggest Mr. Mohamed +20 106 222 2244 .
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
       
        </Box>
    )
}

export default Location;
